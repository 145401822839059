<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center " md="5" lg="5" xl="5">
                        <div class="font-weight-bold body-1">
                            Detalles de Proveedor
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="5" lg="5" xl="5" >

                    <details-item :detailsItems="items" data-cy="data-details-items"></details-item>


                    </v-col>
                    
                </v-row>

               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="5" lg="5" xl="5" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';

export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
               
                
            ],

            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Suppliers",
                block:false,
                click: () => {}
            },
           
          
		}
    },
    mounted(){
        this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
         onResize () {
            
            if(window.innerWidth < 960){
                this.cancelBtnData.block = true;
                
            }
            else{
                this.cancelBtnData.block = false;
                
            }
        },
		
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.supplier.get(this.id)
			.then((response) => {
                console.log(response);
                

                let suppliesText = '';
                response.data?.supplies?.split(",").forEach(br => {
                    suppliesText = suppliesText+`${br}\n`;
                });
                response.data.supplies_text = suppliesText;
               
                this.items= [
                    { concepto: 'Nombre', index: 'prospect', valor:response.data.name},
                    { concepto: 'RFC', index: 'description', valor:response.data.rfc},
                    { concepto: 'Razón social', index: 'project_name', valor:response.data.business_name},
                    { concepto: 'País', index: 'validity', valor:response.data.country},
                    { concepto: 'Estado', index: 'foreign_exchange', valor: response.data.state },
                    { concepto: 'Municipio', index: 'header', valor:response.data.city},
                    { concepto: 'Colonia', index: 'terms', valor:response.data.neighborhood},
                    { concepto: 'Calle', index: 'warehouse', valor:response.data.street},
                    { concepto: 'No. Exterior', index: 'comments', valor:response.data.ext_number},
                    { concepto: 'No. Interior', index: 'comments', valor:response.data.int_number},
                    { concepto: 'Contacto', index: 'comments', valor:response.data.contact},
                    { concepto: 'Teléfono', index: 'comments', valor:response.data.phone},
                    { concepto: 'Correo electrónico', index: 'comments', valor:response.data.email},
                    { concepto: 'Proveedor de ', index: 'comments', valor:response.data.supplies_text},
                    
                ];

            
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 